import React from 'react';
import Profile from '../../assets/images/profile.jpeg';
import { useTranslation } from 'react-i18next';
import Trail from '../Trail';

const Hero: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section id='hero' className="hero w-full min-h-screen flex items-center justify-center bg-base-200">
            <div className='hero container flex justify-between items-center '>
                <div className="flex flex-col text-5xl leading-[68px] font-extrabold text-[#42446E] p-4">
                    <Trail open>
                        <span className='text-neutral'>{t('hi')} 👋,</span>
                        <span className='text-neutral'>{t('my_name_is')}</span>
                        <span className='gradient-text'>Burak</span>
                        <span className='text-neutral'>{t('i_build_things_for_web')}</span>
                    </Trail>
                </div>
                <div>
                    <div className="hidden lg:flex select-none w-[350px] h-[350px] rounded-full shadow-2xl bg-gradient-to-r from-[#00C0FD] to-[#E70FAA] p-4 ">
                        <img src={Profile} alt="Profile" className="rounded-full" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
import React from 'react';
import { TwitterIcon, LinkedinIcon, GithubIcon } from '../../utils/icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <div id='footer' className="w-full flex justify-center items-center bg-base-300">
            <div className='container flex flex-col '>
                <nav className="w-full flex justify-between items-center p-4 flex-col lg:flex-row">
                    <div className="flex flex-col items-center font-extrabold gradient-text">
                        <p className="text-[20px] select-none">{'<'}burakuslu.dev{'/>'}</p>
                    </div>
                    <ul className="flex gap-4 items-center text-neutral flex-col lg:flex-row lg:gap-16">
                        <li className="cursor-pointer"><a href="tel:+905304412311">+90 530 441 23 11</a></li>
                        <li className="cursor-pointer"><a href="mailto:burakuslu541@gmail.com">burakuslu541@gmail.com</a></li>
                        <li className="flex gap-5 cursor-pointer">
                            <a href='https://github.com/burakuslu541' target='_blank'><GithubIcon /></a>
                            <a href='https://x.com/burakuslu551' target='_blank'><TwitterIcon /></a>
                            <a href='https://www.linkedin.com/in/burakuslu541/' target='_blank'><LinkedinIcon /></a>
                        </li>
                    </ul>
                </nav>
                <div className="h-[1px] bg-[#141414]"></div>
                <nav className="text-white w-full flex justify-between items-center p-4 flex-col lg:flex-row">
                    <ul className="hidden gap-16 items-center text-[#666666] text-[16px] lg:flex">
                        <li><a href="#hero" className='text-neutral cursor-pointer'>{t('home')}</a></li>
                        <li><a href="#hero" className='text-neutral cursor-pointer'>{t('about')}</a></li>
                        <li><a href="#tech-stack" className='text-neutral cursor-pointer'>{t('tech_stack')}</a></li>
                        <li><a href="#projects" className='text-neutral cursor-pointer'>{t('projects')}</a></li>
                        <li><a href="#footer" className='text-neutral cursor-pointer'>{t('contact')}</a></li>
                    </ul>
                    <div className="flex gap-1 items-center text-[#666666]">
                        {t('crafted_with')}
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Footer;
import React, { useState } from 'react';
import { TwitterIcon, LinkedinIcon, GithubIcon } from '../../utils/icons';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from "react-country-flag"

const Navbar = () => {
    const [theme, setTheme] = React.useState('light');
    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };
    React.useEffect(() => {
        document?.querySelector('html')?.setAttribute('data-theme', theme);
    }, [theme]);

    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const handleChangeLanguage = () => {
        const newLanguage = currentLanguage === "en" ? "tr" : "en";
        setCurrentLanguage(newLanguage);
        changeLanguage(newLanguage);
    }

    return (
        <div className="navbar bg-base-200 px-4 shadow-lg fixed items-center justify-center w-full z-[99] ">
            <div className="navbar-start">
                <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16" />
                        </svg>
                    </div>
                    <ul
                        tabIndex={0}
                        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
                        <li><a href="/" className='text-neutral h-10 flex items-center'>{t('home')}</a></li>
                        <li><a href="/" className='text-neutral h-10 flex items-center'>{t('about')}</a></li>
                        <li><a href="/tech-stack" className='text-neutral h-10 flex items-center'>{t('tech_stack')}</a></li>
                        <li><a href="/projects" className='text-neutral h-10 flex items-center'>{t('projects')}</a></li>
                        <li><a href="/footer" className='text-neutral h-10 flex items-center'>{t('contact')}</a></li>
                        <li><a href='https://drive.google.com/file/d/1aQtVSSavHBH-RtOkZ4a0G-lqEA39iRDE/view?usp=sharing' target='_blank' className='text-neutral h-10 flex items-center'>{t('download_cv')}</a></li>
                        <li><a href='https://github.com/burakuslu541' target='_blank' className='text-neutral h-10 flex items-center'><GithubIcon /> Github</a></li>
                        <li><a href='https://x.com/burakuslu551' target='_blank' className='text-neutral h-10 flex items-center'><TwitterIcon /> Twitter</a></li>
                        <li><a href='https://www.linkedin.com/in/burakuslu541/' target='_blank' className='text-neutral h-10 flex items-center'><LinkedinIcon /> Linkedin</a></li>
                    </ul>
                </div>
                <div className="flex flex-col items-center gradient-text font-extrabold">
                    <p className="text-[20px] select-none">{'<'}burakuslu.dev{'/>'}</p>
                </div>
            </div>
            <div className="navbar-center hidden lg:flex">
                <ul className="menu menu-horizontal px-1 gap-2">
                    <li><a href="#hero" className='text-neutral h-12 flex items-center'>{t('home')}</a></li>
                    <li><a href="#hero" className='text-neutral h-12 flex items-center'>{t('about')}</a></li>
                    <li><a href="#tech-stack" className='text-neutral h-12 flex items-center'>{t('tech_stack')}</a></li>
                    <li><a href="#projects" className='text-neutral h-12 flex items-center'>{t('projects')}</a></li>
                    <li><a href="#footer" className='text-neutral h-12 flex items-center'>{t('contact')}</a></li>
                    <li><a href='https://drive.google.com/file/d/1aQtVSSavHBH-RtOkZ4a0G-lqEA39iRDE/view?usp=sharing' target='_blank' className='text-neutral h-12 flex items-center'>{t('download_cv')}</a></li>
                    <li><a href='https://github.com/burakuslu541' target='_blank' className='text-neutral h-12 flex items-center'><GithubIcon /></a></li>
                    <li><a href='https://x.com/burakuslu551' target='_blank' className='text-neutral h-12 flex items-center'><TwitterIcon /></a></li>
                    <li><a href='https://www.linkedin.com/in/burakuslu541/' target='_blank' className='text-neutral h-12 flex items-center'><LinkedinIcon /></a></li>
                </ul>
            </div>
            <div className="navbar-end flex items-center space-x-4">
                <label className="swap swap-rotate">
                    {/* this hidden checkbox controls the state */}
                    <input type="checkbox" onClick={toggleTheme} className="theme-controller hidden" value="dark" />

                    {/* sun icon */}
                    <svg
                        className="swap-off h-10 w-10 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                            d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" />
                    </svg>

                    {/* moon icon */}
                    <svg
                        className="swap-on h-10 w-10 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path
                            d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" />
                    </svg>
                </label>
                <div className="flex items-center cursor-pointer" onClick={handleChangeLanguage}>
                    <ReactCountryFlag
                        className="mr-2"
                        countryCode={currentLanguage === "en" ? "GB" : "TR"}
                        svg
                        style={{
                            width: '2em',
                            height: '2em',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Navbar;
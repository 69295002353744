import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import MyTechStach from './components/MyTechStack';
import Projects from './components/Projects';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';
import Gif from './assets/icons/wired-lineal-680-it-developer-hover-pinch.gif';

function App() {
  return (
      <div className="flex flex-col items-center h-full jetbrains-mono-font w-full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>burakuslu | developer</title>
          <link rel="shortcut icon" href={Gif} type="image/x-icon" />
        </Helmet>
          <Navbar />
          <div className="flex flex-col items-center w-full">
            <Hero />
            <MyTechStach />
            <Projects />
            <Footer />
          </div>
      </div>
  );
}

export default App;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactIcon, VscodeIcon, TailwindIcon, SaasIcon, ReduxIcon, JsIcon, HtmlIcon, GreenSockIcon, GithubBigIcon, GitIcon, CssIcon, BootstrapIcon } from '../../utils/icons';

const icons = [ReactIcon, VscodeIcon, TailwindIcon, SaasIcon, ReduxIcon, JsIcon, HtmlIcon, GreenSockIcon, GithubBigIcon, GitIcon, CssIcon, BootstrapIcon]

const MyTechStach: React.FC = () => {
    const { t } = useTranslation();

    return (
        <section id='tech-stack' className="w-full flex justify-center items-center gap-8 min-h-screen bg-base-100">
            <div className="container flex flex-col justify-center items-center gap-8 py-20 px-4">
                <h1 className="text-4xl font-extrabold text-warning">{t('my_tech_stack')}</h1>
                <p className="text-2xl text-neutral text-center">{t('technologies')}</p>
                <div className="flex flex-wrap gap-12 justify-center items-center lg:gap-32">
                    {
                        icons.map((Icon, index) => (
                            <span key={index} className="p-1 w-[120px] h-[120px] flex justify-center items-center bg-base-300 rounded-[10px] shadow-lg">
                                <Icon />
                            </span>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default MyTechStach;
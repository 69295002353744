import React from 'react';
import Rectangle1 from '../../assets/images/Rectangle1.png';
import Rectangle2 from '../../assets/images/Rectangle2.png';
import Rectangle3 from '../../assets/images/Rectangle3.png';
import Rectangle4 from '../../assets/images/Rectangle4.png';
import Rectangle5 from '../../assets/images/Rectangle5.png';
import Rectangle6 from '../../assets/images/Rectangle6.png';
import { ChainIcon, GithubSmIcon } from '../../utils/icons';
import { useTranslation } from 'react-i18next';

interface CardInterface {
    image: string;
    title: string;
    description: string;
    techStack: string[];
    viewCode: string;
};

const Card: React.FC<CardInterface> = (props) => {
    const { t } = useTranslation();
    return (
        <div className="max-w-[375px] max-h-[570px] flex flex-col justify-between items-center bg-base-300 rounded-[20px] shadow-lg">
            <div className="w-full h-[260px]">
                <img src={props?.image} alt="project" className="w-full h-full object-cover rounded-t-[20px]" />
            </div>
            <div className="w-full p-6 flex gap-5 flex-col justify-between">
                <h1 className="text-2xl font-extrabold text-warning">{props?.title}</h1>
                <p className="text-sm text-neutral opacity-40 min-h-[100px]">{props?.description}</p>
                <p className="text-[14px] text-neutral"><span className="font-bold text-primary underline">Tech Stack:</span> {props?.techStack.join(', ')}</p>
                <div className="flex justify-between items-center w-full">
                    <a href={props.viewCode} target="_blank" rel="noreferrer" className="flex gap-2 text-[14px] underline"><GithubSmIcon />{t('view_code')}</a>
                </div>
            </div>
        </div>
    );
};

const Projects: React.FC = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: Rectangle1,
            title: t('react_instagram_clone'),
            description: t('react_instagram_clone_description'),
            techStack: ['React', 'Tailwind'],
            viewCode: 'https://github.com/burakuslu541/react-clone-instagram'
        },
        {
            image: Rectangle2,
            title: 'React Zustand',
            description: t('react_zustand_description'),
            techStack: ['React', 'Zustand', 'Tailwind'],
            viewCode: 'https://github.com/burakuslu541/react-zustand'
        },
        {
            image: Rectangle3,
            title: t('resize_observer'),
            description: t('resize_observer_description'),
            techStack: ['Resize Observer API'],
            viewCode: 'https://github.com/burakuslu541/resizeObserverAPI'
        },
    ];
    return (
        <section id='projects' className="w-full flex flex-col justify-center gap-8 items-center min-h-screen bg-base-200">
            <div className='container flex flex-col justify-center items-center gap-8  py-20'>
                <h1 className="text-4xl font-extrabold text-secondary">{t('projects')}</h1>
                <p className="text-2xl text-neutral text-center">{t('things_i_built_so_far')}</p>
                <div className="flex flex-wrap gap-16 w-full justify-center items-center">
                    {cards.map((card, index) => (
                        <Card key={index} image={card.image} title={card.title} description={card.description} techStack={card.techStack} viewCode={card.viewCode} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Projects;